import React, { useState } from "react"
import LogLink from "../components/LogLink"
import { ContextProviderComponent, SiteContext } from "../context/mainContext"
import Image from "../components/Image"


import { isBrowser, setEventProperties, setPageProperties } from "../../utils/zeotap"
import { getObjectWhoseKeysStartWithPrefix } from "../../utils/helpers"

function ContactUsWithContext(props) {
  setPageProperties({page_category:'ContactUs',page_name:'ContactUs',page_domain:isBrowser() && window.location.hostname})
  return (
    <ContextProviderComponent>
      <SiteContext.Consumer>
        {context => (
            <ContactUs {...props} context={context} />
        )}
      </SiteContext.Consumer>
    </ContextProviderComponent>
  )
}
const Input = ({disabled, onChange, value, name, placeholder }) => (
  <input
    disabled={disabled}
    onChange={onChange}
    value={value}
    className="mt-2 text-sm shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    type="text"
    placeholder={placeholder}
    name={name}
    aria-label="name"
  />
)


const ContactUs = ({ context }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [formSubmitted, setFormSubmit] = useState(false)
  const [input, setInput] = useState({
    name: "",
    email: "",
    message: ""
  })

  const { cart, clearCart } = context;

  const onChange = e => {
    setErrorMessage(null)
    setInput({ ...input, [e.target.name]: e.target.value })
  }


  const handleSubmit = async event => {
    event.preventDefault()
    const { name, email, message } = input
    if (!name || !email || !message) {
      setErrorMessage("Please fill in the form!")
      return
    }

    const info = {
      firstname: name,
      email,
      message,
      ...(!!cart.length && cart[0].catalog === 'automobiles' && {
        productID:cart[0].id,
        product_name:cart[0].name,
        product_price:+cart[0].price,
        ...getObjectWhoseKeysStartWithPrefix(cart[0], 'vehicle')
      } )
    }

    setEventProperties('formSubmit',info)
    setFormSubmit(true)
    clearCart()
  }

  if (formSubmitted) {
    return (
      <>
      <LogLink />
      <div>
        <h3>Submitted Successfully!</h3>
      </div>
      </>
    )
  }

  return (
    <>
     <LogLink />
    <div className="flex flex-col items-center pb-10">
      <div
        className="
            flex flex-col w-full
            c_large:w-c_large
          "
      >
        <div className="pt-10 pb-8">
          <h1 className="text-5xl font-light">Contact us</h1>
        </div>
        {(
            <span>
            {!!cart.length && cart[0].catalog === 'automobiles' ? cart.map((item, index) => {
                return (
                  <div className="py-10" key={index}>
                    <div className="flex items-center">
                      <Image
                        className="w-32 m-0"
                        src={item.image}
                        alt={item.name}
                      />
                      <p className="m-0 pl-10 text-gray-600 text-sm">
                        {item.name}
                      </p>
                    </div>
                  </div>
                )
              }) : null}

            <div className="mt-4 border-t pt-10">
                <form onSubmit={handleSubmit}>
                {errorMessage ? <span>{errorMessage}</span> : ""}
                <Input
                    onChange={onChange}
                    value={input.name}
                    name="name"
                    placeholder="Name"
                />
                <Input
                    onChange={onChange}
                    value={input.email}
                    name="email"
                    placeholder="Email"
                />
                <textarea
                    onChange={onChange}
                    value={input.message}
                    name="message"
                    className="mt-2 h-20 text-sm shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder={'Message'}
                />
                <button
                    type="submit"
                    className="hidden md:block bg-secondary hover:bg-black text-white font-bold py-2 px-4 mt-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Submit
                </button>
                </form>
            </div>
            </span>
        )}
      </div>
    </div>
    </>
  )
}

export default ContactUsWithContext
